<template>
  <div class="container mescroll-touch">
    <div class="header">
      <div class="searchBox" @click="toSearch()">
        <img class="searchIcon" src="@/assets/images/search_comm_icon@2x.png" />
        <span class="searchInput">请输入关键字搜索</span>
      </div>
      <!-- <div class="header_title">
        <div class="titleItem" :class="{'titleItem_active': tcode == item.TCode}" v-for="item in columnList" :key="item.Code" @click="changeTitleMenu(item.TCode)">
          <span>{{item.DisplayName}}</span>
        </div>
      </div> -->
    </div>
    <div class="mescrollBox">
      <iframe class="iframe" ref="iframe" :src="baseUrl+'/web3d/index.html'" frameborder="0"></iframe>
      <div class="titleBox">
        <div class="title">
          <img class="screenIcon" src="@/assets/images/new_home_icon_alt@2x.png" />
          <span>最新上线</span>
        </div>
        <div class="more" @click="toList()">
          <span>更多</span>
          <img src="@/assets/images/more_yellow.png" />
        </div>
      </div>
      <div class="recommendBox" v-if="recommendList.length > 0">
          <div class="recommendItem" v-for="(item, index) in recommendList" :key="index" @click="toContentPage(item.Id, item.Type)">
            <div class="item_img">
              <img class="item_cover" :src="item.Thumbnail" />
              <img class="playIcon" v-if="item.Type == 1 || item.Type == 3" src="@/assets/images/play_comm_icon@2x.png" />
            </div>
            <div class="item_content">
              <span class="item_title">{{item.Title}}</span>
              <div class="item_flexbox">
              <p class="item_time">{{item.yyyymmdd}}</p>
              <div class="item_nums">
                <div class="nums">
                  <img class="watchIcon" src="@/assets/images/watch_yellow.png" />
                  <span>{{item.WatchCount}}</span>
                </div>
                <div class="nums">
                  <img class="likeIcon" src="@/assets/images/like_yellow.png" />
                  <span>{{item.LikeCount}}</span>
                </div>
              </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="goBackZone" v-if="entry == 'enteringZone'" @click="goBackZone">返回专区</div>
  </div>
</template>

<script>
export default {
  name: 'CaseZoneWeb3D',
  data() {
    return {
      columnList: [],
      tcode: '',
      recommendList: [],
      baseUrl: process.env.VUE_APP_BASE_URL,
      entry: null
    }
  },
  created() {
    // this.getColumn();
    this.getRecommendList();
    this.entry = sessionStorage.getItem("entry");
  },
  mounted() {
    // this.setIframeViewport();
  },
  methods: {
    setIframeViewport() {
      console.log('setIframeViewport')
      const iframe = this.$refs.iframe;
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
      const meta = iframeDoc.createElement('meta');
      meta.setAttribute('name', 'viewport');
      meta.setAttribute('content', 'user-scalable=no');
      iframeDoc.head.appendChild(meta);
    },
    // 获取栏目
    // getColumn() {
    //   this.$axios.get('/Api/api/Web/Article/GetCode?code=cnvblzq').then(res => {
    //     let data = res.Data;
    //     if(data.length > 0) {
    //       this.tcode = data[0].TCode;
    //       this.columnList = data;
    //       this.getRecommendList();
    //     }
    //   })
    // },
    changeTitleMenu(tcode) {
      this.tcode = tcode;
      this.recommendList = [];
      this.getRecommendList();
    },
    // 获取最新推荐列表数据
    getRecommendList() {
      this.$axios.post('/Api/Api/Web/Article/GetArticleList', {
        SubjectTCode: 'cnvblzq|',
        ModuleType: 7,
        PageIndex: 1,
        PageSize: 2
      }).then((res) => {
        if(res.Data.List) {
          this.recommendList = res.Data.List;
        }
      }).catch((e) => {
      })
    },
    toSearch() {
      this.$router.push({
        path: 'search',
        query: {
          code: 'cnvblzq'
        }
      });
    },
    toList() {
      this.$router.push({
        path: 'caseZoneList',
        query: {
          type: 1
        }
      });
    },
    toContentPage(Id, Type) {
      this.$router.push({
        path: 'contentPage',
        query: {
          id: Id,
          type: Type
        }
      });
    },
    goBackZone() {
      this.$router.push('/enteringZone');
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-image: url('../../assets/images/header_bg2.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #F8F8F8;
  .header {
    padding: 15px 15px 10px;
    .searchBox {
      display: flex;
      align-items: center;
      flex: 1;
      height: 35px;
      line-height: 35px;
      border-radius: 35px;
      background-color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      padding: 0 15px;
      margin-bottom: 10px;
      .searchIcon {
        width: 15px;
        height: 15px;
      }
      .searchInput {
        flex: 1;
        margin-left: 8px;
        border: none;
        color: #999999;
      }
    }
    .header_title{
      display: flex;
      align-items: center;
      .titleItem {
        position: relative;
        padding: 0 10px;
        font-size: 15px;
        color: #FFFFFF;
        span {
          display: inline-block;
          width: 100%;
        }
      }
      .titleItem_active::after {
        content: "";
        position: absolute;
        left: 37%;
        width: 26%;
        height: 3px;
        border-radius: 3px;
        background-color: #FFFFFF;
        bottom: -7px;
      }
    }
  }
  .mescrollBox {
    position: fixed;
    width: 100%;
    top: 65px;
    bottom: 0;
    height: auto;
    border-radius: 12px 12px 0 0;
    background-color: #F8F8F8;
    padding: 15px 15px 10px;
    box-sizing: border-box;
    overflow: auto;
    .titleBox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .title {
        width: auto;
        display: flex;
        align-items: center;
        height: 30px;
        border-radius: 30px;
        padding: 0 10px;
        box-sizing: border-box;
        font-size: 14px;
        background-color: $themeColor;
        color: #FFFFFF;
        img {
          display: block;
          width: 16px;
          height: 14px;
          margin-right: 4px;
        }
      }
      .more{
        display: flex;
        align-items: center;
        font-size: 12px;
        color: $themeColor;
        img {
          width: 14px;
          height: 14px;
          margin-left: 4px;
        }
      }
    }
    .recommendBox {
      display: flex;
      margin-bottom: 10px;
      min-height: 50px;
      .recommendItem {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: calc(calc(100% / 2) - 5px);
        border-radius: 6px;
        background-color: #FFFFFF;
        overflow: hidden;
        .item_img {
          position: relative;
          .item_cover {
            display: block;
            width: 100%;
          }
          .playIcon {
            position: absolute;
            right: 12px;
            bottom: 12px;
            width: 30px;
            height: 30px;
          }
        }
        .item_content {
          padding: 5px 8px 8px;
          .item_title {
            height: 35px;
            font-size: 13px;
            line-height: 17px;
            color: #333333;
            overflow: hidden;
            text-overflow:ellipsis;
            display:-webkit-box;
            -webkit-box-orient:vertical;
            -webkit-line-clamp:2;
            word-wrap: break-word;
          }
          .item_time {
            font-size: 12px;
            color: #999999;
            line-height: 24px;
          }
          .item_label {
            display: flex;
            flex-wrap: wrap;
            span {
              display: inline-block;
              height: 20px;
              line-height: 20px;
              border-radius: 2px;
              padding: 0 6px;
              margin-right: 6px;
              margin-bottom: 5px;
              background-color: rgba(255, 73, 98, 0.15);
              font-size: 11px;
              color: #CA001B;
            }
          }
          .item_nums {
            display: flex;
            justify-content: flex-end;
            .nums {
              display: flex;
              align-items: center;
              .watchIcon {
                width: 14px;
                margin: 0 2px 0 6px;
              }
              .likeIcon {
                width: 11px;
                margin: 0 2px 0 6px;
              }
              span {
                font-size: 11px;
                color: #666666;
              }
            }
          }
        }
      }
      .recommendItem:not(:last-child) {
        margin-right: 10px;
      }
    }
    .iframe {
      width: 100%;
      height: 600px;
      margin-bottom: 15px;
      border: 2px solid #7C8390;
      box-sizing: border-box;
    }
  }
}
</style>